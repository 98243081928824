/* Estilos gerais da seção de depoimentos */
.testimonials-section {
    color: #333;
    text-align: center;
    padding: 4rem 2rem;
    background-color: #f8f8f8;
    background-size: 500px; /* Ajuste o tamanho conforme necessário */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.depoimentos-label {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #191331;
    color: #fff;
    font-size: 0.8rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    font-weight: bold;
    text-align: center;
}

/* Container do conteúdo do depoimento */
.testimonial-content {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Garante que cada elemento do conteúdo fique centralizado */
    gap: 1.5rem;
}

.stars {
    color: #f1c40f;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 5px; /* Espaçamento entre as estrelas */
}

.testimonial-text {
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 600px;
    color: #333;
    font-style: italic;
    text-align: center;
    margin: 0 auto; /* Centraliza o texto */
}

/* Estilos para o rodapé do depoimento (imagem e autor) */
.testimonial-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 1rem;
}

.testimonial-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.author-info {
    text-align: left;
}

.author {
    font-weight: bold;
    margin: 0;
}

.role {
    color: #666;
    font-size: 0.8rem;
    margin: 0;
}

/* Botão de chamada para ação */
.cta-btn {
    display: inline-block;
    margin-top: 2rem;
    padding: 12px 24px;
    background-color: #f1c40f;
    color: #1a1a2e;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    align-self: center; /* Centraliza o botão dentro do contêiner flex */
}

.cta-btn:hover {
    background-color: #e0b020;
}
