/* Geral da Seção de Contato */
.contact-section {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container {
    display: flex;
    max-width: 1200px;
    width: 100%;
    align-items: stretch; /* Garante que os elementos internos ocupem a altura completa */
}

.contact-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-image img {
    height: 100%; 
    width: 100%;
    border-radius: 8px;
    object-fit: cover; /* Garante que a imagem preencha o espaço sem distorção */
}

.contact-form {
    flex: 1;
    padding: 60px 20px;
    max-width: 500px;
}

.contact-label {
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: #191331; /* Fundo escuro para contraste */
    color: #ffffff; /* Texto claro */
    padding: 0.5rem 1rem;
    border-radius: 8px;
    margin-bottom: 20px;
    display: inline-block;
}

.contact-form h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: left;
    padding: 0;
    margin: 0;
    color: #333333 !important; /* Texto escuro */
}

.contact-form p {
    font-size: 1.1rem;
    margin-bottom: 30px;
    padding: 0;
    text-align: start;
    color: #555555; /* Texto em tom intermediário */
}

form {
    max-width: 600px;
    margin-top: 20px;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

.form-group label {
    display: block;
    font-size: 1rem;
    color: #555555; /* Texto em tom intermediário */
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    background-color: #ffffff; /* Fundo claro */
    color: #333333; /* Texto escuro */
    border: 1px solid #ddd; /* Borda para o campo */
    border-radius: 5px;
    font-size: 1rem;
}

textarea {
    height: 150px;
}

.submit-btn {
    width: 100%;
    padding: 15px;
    background-color: #f1c40f; /* Cor do botão */
    color: #333333; /* Texto escuro no botão */
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #e1b80d; /* Cor de hover do botão */
}

/* Bottom Footer Section */
.bottom-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff; /* Fundo claro */
    color: #333333; /* Texto escuro */
    border-top: 1px solid #ddd; /* Linha divisória */
}

.bottom-left {
    margin-left: 100px;
    font-size: 0.8rem;
    color: #9CA3AF !important;
}

.bottom-left h3 {
    margin: 0;
    font-size: 1.3rem;
    font-weight: bold;
}

.bottom-right {
    display: flex;
    gap: 10px;
    margin-right: 100px;
}

.circle {
    width: 20px;
    height: 20px;
    background-color: #666666; /* Círculos decorativos */
    border-radius: 50%;
}

.footer-icon {
    width: 30px; /* Ajuste o tamanho conforme necessário */
    height: 30px;
    margin-right: 10px; /* Espaçamento entre os ícones */
}

/* Responsivo */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .contact-form,
    .contact-image {
        width: 100%;
    }

    .bottom-footer {
        flex-direction: column;
        text-align: center;
    }

    .bottom-left {
        margin: 0;
    }

    .bottom-right {
        margin: 0;
        margin-top: 20px;
    }
}
