/* Estilos gerais da seção de valores */
.values-section {
    padding: 4rem 2rem;
    background-color: #1a1a2e;
    color: #ffffff;
    text-align: center;
}

.values-label {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: white;
    color: #191331;
    font-size: 0.8rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    font-weight: bold;
}

h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #ffffff !important;
    text-align: center;
}

/* Container dos valores com layout de 3 colunas */
.values-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

/* Estilos de cada item de valor */
.value-item {
    padding: 2rem;
    border-radius: 8px;
    text-align: start;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.value-icon {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    font-size: 2.5rem;
    color: #191331; /* Cor do ícone */
    margin-bottom: 1rem;
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #ffffff;
}

.value-item p {
    font-size: 1rem;
    line-height: 1.6;
    color: #bbbbbb;
    padding: 0;
}

/* Responsivo */
@media (max-width: 768px) {
    .values-container {
        grid-template-columns: 1fr; /* Coluna única em telas menores */
    }

    .values-section h2 {
        font-size: 2rem;
    }
}
