@import '~@fortawesome/fontawesome-free/css/all.min.css';

/* Geral da Seção de Perguntas Frequentes */
.faq-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    background-color: #1a1a2e;
    color: #ffffff;
}

.faq-content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza o conteúdo da seção */
}

/* Estilos do rótulo FAQ */
.faq-label {
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: white;
    color: #191331;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    display: inline-flex; /* Flex para centralizar conteúdo */
    align-items: center;
    justify-content: center;
    text-align: center;
}

.faq-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
}

.faqs {
    margin-top: 20px;
    width: 100%;
}

.faq-item {
    border-bottom: 1px solid #333;
    padding: 15px 0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq-question h3 {
    font-size: 1.2rem;
    color: #ffffff;
    margin: 0;
}

.faq-question span {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    color: #ffffff;
}

.faq-answer {
    margin-top: 10px;
}

.faq-answer p {
    font-size: 1rem;
    color: #bbbbbb;
    line-height: 1.6;
    padding: 20px 0;
}

/* Responsivo */
@media (max-width: 768px) {
    .faq-section {
        padding: 40px 20px;
    }

    .faq-content {
        width: 100%;
    }

    .faq-question h3 {
        font-size: 1rem;
    }

    .faq-section h2 {
        font-size: 1.8rem;
    }
}
