/* Styles for Services component */

.services-section {
    display: flex;
    flex-direction: column;
    padding: 60px 20px;
    background-color: #1a1a2e;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.services-section::before {
    content: '';
    position: absolute;
    background-size: 500px;
    opacity: 0.05;
    width: 500px;
    height: 500px;
    top: 20%;
    margin-right: 50px;
    transform: translateX(-50%);
}

.services-label {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: white;
    color: #191331;
    font-size: 0.8rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    font-weight: bold;
}

.services-section h2 {
    padding: 0 1.5rem;
    text-align: start !important;
    font-size: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.services-section p {
    display: flex;
    padding: 1.5rem;
    text-align: start !important;
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: #bbbbbb;
}

.services-container {
    display: grid;
    max-width: 1200px;
    width: 100%;
    align-items: center;
    grid-template-columns: repeat(1, 1fr); /* 1 coluna por padrão (mobile) */
    gap: 1.5rem;
    margin: 1rem 0;
}

@media (min-width: 768px) {
    .services-container {
        grid-template-columns: repeat(2, 1fr); /* 2 colunas para tablets */
    }
}

@media (min-width: 1024px) {
    .services-container {
        grid-template-columns: repeat(3, 1fr); /* 3 colunas para desktops */
    }
}

.service-item {
    padding: 1.5rem;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.service-icon {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #1a1a2e; /* Ajuste a cor dos ícones conforme necessário */
}

h3 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    color: #ffffff;
}

.service-item p {
    padding: 0;
    font-size: 1rem;
    color: #bbbbbb;
    line-height: 1.5;
    text-align: start !important;
}
