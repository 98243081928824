/* Geral da Seção de Projetos */
.projects-section {
    padding: 4rem 2rem;
    color: #333 ;
    text-align: center;
}

.projetos-label {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #191331;
    color: #fff;
    font-size: 0.8rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    font-weight: bold;
}

.projects-section h2 {
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #333 !important;
}

.project-container {
    display: flex;
    flex-direction: row;
    align-items: center; /* Alinha verticalmente o conteúdo ao centro */
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.project-image {
    flex: 1;
    max-width: 50%; /* Define o espaço para a imagem */
}

.project-image img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.project-details {
    flex: 1;
    text-align: left;
    max-width: 50%; /* Define o espaço para a descrição */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Alinha o conteúdo ao início verticalmente */
}

.project-details h3 {
    font-size: 1.8rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 1rem;
}

.project-details p {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 1rem;
}

/* Centraliza o botão abaixo da descrição */
.project-btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #f1c40f;
    color: #191331;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    margin-top: 1.5rem;
    align-self: flex-start; /* Alinha o botão ao início do conteúdo */
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.project-btn:hover {
    background-color: #e0b020;
}

/* Responsivo */
@media (max-width: 768px) {
    .project-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .project-image,
    .project-details {
        max-width: 100%;
    }

    .project-details {
        text-align: center;
    }

    .project-btn {
        align-self: center;
    }
}
