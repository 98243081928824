  header {
    background-color: white !important; 
    padding: 15px 20px;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white !important; 
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .logo h1 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
  }
  
  nav ul {
    list-style: none;
    display: flex;
  }
  
  nav ul li {
    margin: 0;
    background-color: white;
  }
  
  nav ul li a {
    text-decoration: none;
    color: gray !important;
    font-size: 1rem;
    padding: 10px;
    transition: color 0.3s;
  }
  
  nav ul li a:hover {
    color: #555;
  }
  
  .cta-button a {
    padding: 10px 20px;
    background-color: #fff;
    border: none; /* Remover a borda */
    color: #333;
    border-radius: 10px; /* Borda arredondada */
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    font-weight: 500;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  }

  .cta-button a:hover {
      background-color: #f4f4f4;
      color: #333;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Aumenta a sombra no hover */
  }

  .mobile-menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .mobile-menu-icon span {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
  }
  
  @media (max-width: 768px) {
    nav {
      display: none;
    }
  
    .mobile-menu-icon {
      display: flex;
    }
  
    .cta-button {
      display: none;
    }
  
    .menu.active {
      display: block;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      background-color: white !important;
      text-align: center;
      border-top: 2px solid #ddd; 
    }
  
    .menu ul {
      flex-direction: column;
      gap: 0;
    }
  
    .menu ul li {
      padding: 15px 0;
      background-color: white !important;
    }
  
    .logo {
      margin-left: 25px;
      text-align: center;
      width: 100%;
    }
  }
  