/* General Styling for Hero Section */
.hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 60px 20px;
    height: 100vh;
}

/* Partner Circles */
.circles {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.circle {
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Heading and Subheading */
.hero-section h1 {
    font-size: 4rem;
    font-weight: bold;
    color: #222;
    margin-bottom: 20px;
}

.hero-section h1 .highlight {
    color: #f1c40f;
}

.hero-section p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 30px;
}

/* Call-to-Action Button */
.cta-btn {
    padding: 12px 24px;
    background-color: #f1c40f;
    color: #191331;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    text-decoration: none;
}

.cta-btn:hover {
    background-color: #e0b020;
}

/* Technology Marquee */
.technology-marquee {
    overflow: hidden;
    background-color: #fff;
    padding: 40px;
}

.marquee-content {
    display: flex;
    gap: 40px;
    animation: scrollMarquee 30s linear infinite;
    transform: translate3d(0, 0, 0); /* GPU acceleration */
}

.marquee-content img {
    height: 40px;
    transition: transform 0.3s ease;
}

.marquee-content img:hover {
    transform: scale(1.1);
}

/* Keyframes for Infinite Scrolling */
@keyframes scrollMarquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}

/* Responsive */
@media (max-width: 768px) {
    .hero-section h1 {
        font-size: 2rem;
    }
    .hero-section p {
        font-size: 1rem;
    }
    .cta-btn {
        padding: 10px 20px;
    }
}
