@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', Arial, sans-serif;
}

header {
  background-color: #333;
  padding: 10px 0;
}

header nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
}

header nav ul li {
  margin: 0 15px;
}

header nav ul li a {
  color: white;
  text-decoration: none;
}

.hero-section {
  text-align: center;
  padding: 50px 20px;
}

.hero-section h1 {
  font-size: 2.5rem;
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  header nav ul {
    flex-direction: column;
  }
}
